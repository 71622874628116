// import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet";

// export default function BlogCard({
//   blog: {
//     title,
//     description,
//     id,
//     image: { imageUrl, altDescription },
//     tags,
//   },
// }) {
//   return (
//     <div>
//       <Helmet>
//         <meta name="description" content={description} />
//         <meta name="keywords" content={tags?.join(", ")} />
//         <meta property="og:title" content={title} />
//         <meta property="og:description" content={description} />
//         <meta property="og:image" content={imageUrl} />
//         <meta
//           property="og:url"
//           content={`${window.location.origin}/blog/${title}`}
//         />
//         <meta property="og:type" content="article" />
//         <meta name="twitter:card" content="summary_large_image" />
//         <meta name="twitter:title" content={title} />
//         <meta name="twitter:description" content={description} />
//         <meta name="twitter:image" content={imageUrl} />
//       </Helmet>
//       <div className="flex flex-col gap-3 border rounded-md shadow-sm" key={id}>
//         <div className="w-full">
//           <img
//             src={imageUrl}
//             alt={altDescription}
//             className="w-full object-cover max-h-[500px] rounded-md"
//           />
//           <div className="flex flex-wrap gap-1 p-4">
//             {tags?.map((tag, index) => (
//               <button
//                 key={index}
//                 className="border p-2 rounded-md bg-blue-300 hover:text-white hover:bg-blue-800"
//               >
//                 {tag}
//               </button>
//             ))}
//           </div>
//         </div>
//         <div className="px-4">
//           <Link
//             to={`/blog/${title.replace(/ /g, "-")}`}
//             className="text-black hover:!text-blue-600 "
//           >
//             <h1 className="text-3xl">{title}</h1>
//           </Link>
//           <p className="text-justify">{description}</p>
//         </div>
//       </div>
//     </div>
//   );
// }


import React from "react";
import { Helmet } from "react-helmet";
import C1 from "../../image/c1.jpg";
import C2 from "../../image/c2.jpg";

export default function SingleBlog() {
  const blogs = [
    {
      title: "Certificate of Incorporation: Mindful Haven LLP",
      description:
        "Explore the journey of Mindful Haven LLP's incorporation process and how it became a registered entity. Learn about the significance of this milestone and its impact on the organization's future.",
      image: {
        imageUrl: C1,
        altDescription: "Certificate of Incorporation for Mindful Haven LLP",
      },
      tags: ["Certificate", "Incorporation", "Business"],
      content: `
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        This article dives into the details of the Certificate of Incorporation for Mindful Haven LLP, highlighting the steps and importance of formalizing a business entity.
      `,
    },
    {
      title: "Understanding FBR Regulations and Compliance",
      description:
        "A comprehensive guide to navigating the Federal Board of Revenue (FBR) regulations, including tax compliance, registration processes, and their significance for businesses in Pakistan.",
      image: {
        imageUrl: C2,
        altDescription: "Overview of FBR Regulations and Compliance",
      },
      tags: ["FBR", "Taxation", "Compliance"],
      content: `
        Ut in tortor nec orci gravida vehicula sit amet nec odio. 
        This article provides insights into FBR regulations, including the importance of tax compliance, registration requirements, and how businesses can ensure adherence to these rules.
      `,
    },
  ];
  

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Certifications</title>
        <meta name="description" content="Static blogs displayed on the page" />
        <meta name="keywords" content="React, Static Blogs, SEO" />
      </Helmet>

      {/* Render All Blogs */}
      {blogs.map((blog, index) => (
        <div
          key={index}
          className="flex flex-col gap-3 border rounded-md shadow-sm mb-8"
        >
          {/* Blog Image */}
          <div className="w-full h-auto">
            <img
              src={blog.image.imageUrl}
              alt={blog.image.altDescription}
              className="w-full h-auto object-contain rounded-md"
              style={{ maxHeight: "500px" }}
            />
          </div>
          {/* Tags */}
          {/* <div className="flex flex-wrap gap-1 p-4">
            {blog.tags.map((tag, idx) => (
              <button
                key={idx}
                className="border p-2 rounded-md bg-blue-300 hover:text-white hover:bg-blue-800"
              >
                {tag}
              </button>
            ))}
          </div> */}
          {/* Blog Content */}
          <div className="px-4">
            <h1 className="text-3xl font-semibold">{blog.title}</h1>
            <p className="text-justify">{blog.description}</p>
            <div className="mt-4">
              <p>{blog.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
