import img1 from "../../image/bgs.png";
import imgsoft from "../../image/CEO.jpeg";
import "./home.css";
import { Helmet } from "react-helmet";
import Marquee from "react-fast-marquee";
import imgs2 from "../../image/Patronlogo.png";
import imgs3 from "../../image/logo-white.png";
import imgs5 from "../../image/BelgiumImmo.png";
import imgs7 from "../../image/twitter.png";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";

import first from "../../image/review1.jpeg";
import second from "../../image/review2.jpeg";
import third from "../../image/review3.jpeg";
import fourth from "../../image/review4.jpeg";
import fifth from "../../image/review5.jpeg";
import sixth from "../../image/review6.jpeg";
import Slider from "react-slick";

// ===============================
// import homepageImageOne from "../../image/home/home-page-image (1).png";
// import homepageImageTwo from "../../image/home/home-page-image (2).png";
import software from "../../image/Counseling.jpeg"
import innoviative from "../../image/clinical.jpeg"
import Talent from "../../image/training.jpeg"
import social from "../../image/social.jpeg"
import support from "../../image/donations.jpeg"
// import homepageImage2Three from "../../image/home/home-page-image (3).png";
import contactus from "../../image/contactus.svg";
import Changeslog from "./Changeslog";

const companyOverView = [
  {
    label: "Clinical Department",
    description:
      "<p>Mindful Haven's Clinical Psychology Department offers pro-bono mental health services to foster emotional well-being. Our mission is to provide ethical care, develop students' skills with hands-on experience, and raise community awareness through webinars and educational content. Key activities include content creation, collaborative webinars, and expert feedback. Join us to support our mission, learn more about our services, and participate in webinars.</p>",
    imageUrl:innoviative ,
  },
  {
    label: "Counseling Department",
    description:
      "<p>Mindful Haven's Counseling Department provides compassionate services for individuals, families, and groups. Our licensed professionals offer programs in various counseling forms, including play therapy, using evidence-based approaches like cognitive-behavioral and mindfulness therapies. With flexible scheduling, online options, and a focus on confidentiality, we make mental health support accessible. Contact us today to start your journey to wellness.</p>",
    imageUrl: software,
  },
  {
    label: "Training & Education Department",
    description:
      "<p>Mindful Haven's Training and Education Department promotes mental health awareness and professional development through webinars, workshops, and courses. We focus on breaking stigmas, managing stress, and addressing trauma while supporting diversity and inclusion. Our programs serve professionals, students, educators, and corporate partners. Join us to enhance mental health knowledge and skills.</p>",
    imageUrl: Talent ,
  },
  {
    label: "Social Media Department",
    description:
      "<p>Mindful Haven's Social Media Department creates engaging, inclusive content to promote mental health awareness and break stigmas. Our team of designers and digital marketing experts manage platforms like Instagram, Facebook, and YouTube, fostering meaningful connections through inspiring visual narratives and consistent branding.</p>",
    imageUrl: social ,
  },
  {
    label: "Donation Department",
    description:
      "<p>Mindful Haven's Donation Department helps individuals facing mental health challenges by ensuring equitable access to care through community support. Your contributions enable subsidized services and enhance awareness. Donate through monetary gifts, in-kind donations, or volunteer efforts. Every contribution makes a difference. Contact us at +923443942550 or mindfulhaven194@gmail.com to get involved today.</p>",
    imageUrl: support ,
  },
];

const Home = () => {
  const settings = {
    Infinite: true,
    speed: 500,
    slidesToShow: 2,
    slideToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title data-react-helmet="true">Mindful Haven</title>
        <meta
          name="description"
          content="Mindful Haven, a non-profit organization empowering mental wellness and resilience. Our five key departments work together to achieve this mission: Clinical Department, Counseling Department, Training and Education Department, Social Media Department, and Donation Department. Through these departments, we provide comprehensive mental health services, education, and support to individuals and communities."
          data-rh="true"
        />
        <meta
          name="keywords"
          content=" Mindful Haven Organization"
        />
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <link rel="canonical" href="/" />
      </Helmet>
      <section
        id="header"
        className="d-flex align-items-center homeimg text-white "
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-12 col-md-10 mx-auto home-main">
              <div className="container1">
                <Fade left duration={1000}>
                  <div className="min-head col-lg-12 col-m  d-10 col-sm-12 pt-5 pt-lg-0 d-flex justify-content-center flex-column text-center mt-[-80px] md:mt-0">
                  <Changeslog/>
                    <p className=" text-3xl md:text-6xl maintext text-black  col-lg-8  mx-auto"><b>
                    Mindful  <span className="text-textColor"> Haven </span> </b></p>
                    <p className="pt-3 text-black text-center col-lg-9 self-center mx-3 md:mx-0 text-xl leading-relaxed col-lg-4">
                    {/* An American based organization, TechCreator, We Don't just automate Business.We understand your business and Anticipate its Needs. Our vision  is to Empower  your Growth  journey with Cutting-Edge Automation solutions.    */}

                    Welcome to Mindful Haven, a non-profit organization empowering mental wellness and resilience. Our five key departments work together to achieve this mission: Clinical Department, Counseling Department, Training and Education Department, Social Media Department, and Donation Department. Through these departments, we provide comprehensive mental health services, education, and support to individuals and communities.                    </p>
                    {/* <div className="mt-3 col-sm-6 d-flex col-lg-12 justify-content-center items-center self-center">
                      <button
                        className="btn btn-con1"
                        onClick={() => navigate("/contact")}
                      >
                        AUTOMATE YOUR BUSINESS
                      </button>
                    </div> */}
                  </div>
                </Fade>

                {/* <div className="col-12 col-lg-8 lg:h-[400px] mx-auto col-md-12 col-sm-12  mb-5 animation">
                  <img
                    src={img1}
                    style={{ boxShadow: "2px 2px 20px #8c40d4" }}
                    className="img-fluid "
                    alt="TechCreator software company"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="header2"
        className="d-flex align-items-center mt-5  text-dark"
      >
        <div className="container-fluid nav_bg">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <Fade left duration={1000}>
                  <div className="col-lg-7 col-md-12 col-sm-12 soft-img">
                    <img src={imgsoft} alt="Mindful Haven CEO Husna Naeem" />
                  </div>
                </Fade>
                <Fade
                  bottom
                  delay={1000}
                  duration={1000}
                  style={{ overflow: "hidden" }}
                >
                  {/* <div className="col-lg-5 col-md-12 col-sm-12 hero-dev  d-flex justify-content-center flex-column">
                    <h2 className="mt-3 Develop">
                      Our Comprehensive Software Solutions
                    </h2>
                    <p className="pt-3  text-justify">
                      At TechCreator Software House, we believe that client
                      satisfaction is key to our success, and we are committed
                      to providing exceptional customer service at every stage
                      of the
                      <a href="https://en.wikipedia.org/wiki/Software_development_process">
                        software development process
                      </a>
                      . Our team works closely with clients to ensure that their
                      vision is realized, and their software
                      solution is a success.
                    </p>
                  </div> */}
                  <div className="col-lg-5 col-md-12 col-sm-12 hero-dev  d-flex justify-content-center flex-column">
  {/* <h2 className="mt-3 Develop">
    Leadership in Mental Health Care
  </h2> */}
  <p className="pt-3  text-justify">
  As the CEO of Mindful Haven Organization, I, Husna Naeem, bring extensive experience in mental health care, having worked in multiple hospitals, drug rehabilitation centers, and clinical settings. With a strong background in counseling, I specialize in various therapies, including couple, individual, family, and group therapy, as well as cognitive-behavioral and mindfulness-based approaches. Throughout my career, I have developed and implemented effective treatment plans, provided guidance and support to diverse patient populations, and collaborated with healthcare professionals to ensure comprehensive care. I am committed to delivering high-quality mental health services, promoting awareness, and fostering a supportive community through Mindful Haven Organization.
  </p>
</div>

                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

<section className="flex flex-col bg-gray-100 py-5 gap-20 px-5">
  {companyOverView.map((item, index) => (
    <div
      className={`flex sm:flex-row flex-col justify-around m-auto gap-5 ${
        index % 2 === 0 ? "sm:flex-row-reverse" : ""
      }`}
      key={index}
    >
      <Fade duration={1000} direction={index % 2 === 0 ? "right" : "left"}>
        <div className="flex-1 max-w-2xl">
          <img
            src={item.imageUrl}
            className="rounded-lg w-full max-h-72 object-contain"
            alt={item.label}
          />
        </div>
      </Fade>

      <Fade bottom delay={500} duration={1000}>
        <div className="flex-1 max-w-xl">
          <h1 className="font-bold text-[rgb(80, 89, 25)]">{item.label}</h1>
          <div
            className="text-justify text-[black]"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>
      </Fade>
    </div>
  ))}
</section>


      <Fade bottom duration={1000}>
        <div className="col-md-12 clint-div text-center pt-5 my-5 p-1">
          <h2 className="text-[rgb(133, 150, 21)]">CLIENT TESTIMONIALS</h2>
          <h3>What they say about us</h3>
        </div>
      </Fade>

      <section className="card-img">
        <div className="container py-5 ">
          <div className="flex  flex-wrap gap-1 justify-center  items-center p-5 overflow-x-hidden">
            {/* <Slider
              {...settings}
              className="w-[260px]  sm:w-full  md:w-[1150px]  h-[150pc ]"
            >
              <img className="object-cover" alt="reviews" src={first} />
              <img className="object-cover" alt="reviews" src={third} />
              <img className="object-cover" alt="reviews" src={second} />
              {/* <img className="object-cover" alt="reviews" src={ss} /> *
              <img className="object-cover" alt="reviews" src={fourth} />
            </Slider> */}

<Slider
  {...settings}
  className="w-[260px] sm:w-full md:w-[1150px]"
>
  <img className="h-[150px] w-[150px] sm:h-[200px] sm:w-full object-contain mx-auto" alt="Mindfule haven reviews" src={first} />
  <img className="h-[150px] w-[150px] sm:h-[200px] sm:w-full object-contain mx-auto" alt="Mindfule haven reviews" src={second} />
  <img className="h-[150px] w-[150px] sm:h-[200px] sm:w-full object-contain mx-auto" alt="Mindfule haven reviews" src={third} />
  <img className="h-[150px] w-[150px] sm:h-[200px] sm:w-full object-contain mx-auto" alt="Mindfule haven reviews" src={fourth} />
  <img className="h-[150px] w-[150px] sm:h-[200px] sm:w-full object-contain mx-auto" alt="Mindfule haven reviews" src={fifth} />
  <img className="h-[150px] w-[150px] sm:h-[200px] sm:w-full object-contain mx-auto" alt="Mindfule haven reviews" src={sixth} />
</Slider>


          </div>

          <div className="row flex justify-center">
            {/* start count jsx */}
            <div className="row">
              <Fade bottom duration={2000}>
                <div className=" d-md-flex col-10 mx-auto">
                  <div className="col-md-4 col-sm-12 count">
                    <p>
                      +<span>100</span>
                      <span className="my-span">CLIENTS</span>{" "}
                    </p>
                  </div>

                  <div className="col-md-5 col-sm-12 count">
                    <p>
                      +<span>20</span>
                      <span className="my-span">Psychologist</span>{" "}
                    </p>
                  </div>
                  
                  <div className="col-md-3 col-sm-12 count">
                    <p>
                      +<span>500</span>
                      <span className="my-span">Students</span>{" "}
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid py-5">
        <div className="row">
          <Fade bottom duration={1000}>
            <div className="flex justify-center items-center gap-x-5 flex-col md:flex-row  mx-auto ">
              <div className="max-w-[600px]">
                <div className="col-lg-12 col-md-12 col-sm-12  pt-lg-0  proj-header ">
                  <h2 className="font-semibold text-[rgb(133, 150, 21)]">
                  Get in Touch with Mindful Haven
                  </h2>
                </div>
                <p className="text-justify text-[black]">
                At Mindful Haven, we value your feedback, questions, and concerns. Our dedicated team is here to support you. Whether you're seeking information, resources, or simply wanting to share your thoughts, we're committed to listening.
                </p>

                <div className="pro-main col-lg-6 col-md-12 col-sm-12  d-md-flex  justify-content-start">
                  <button
                    className="proj-btn"
                    onClick={() => navigate("/contact")}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
              <div>
                <img src={contactus} alt="contact-us" className="w-[310px]" />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};
export default Home;
