import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./TopNav.css";
import logo from "../image/logo.png";
import { GrUserAdmin } from "react-icons/gr";
import { useAdminContextStates } from "../context/AdminContext";
import AdminMenu from "./AdminMenu";

const TopNav = () => {
  const { admin } = useAdminContextStates();
  const menuData = [
    {
      path: "/",
      name: "Home",
    },
    {
      path: "/about",
      name: "About",
    },
    // {
    //   path: "/services",
    //   name: "Services",
    // },
    // {
    //   path: "/pri",
    //   name: "Pricing",
    // },
    {
      path: "/certification",
      name: "Certification",
    },
    // {
    //   path: "/portfolio",
    //   name: "Portfolio",
    // },
    // {
    //   path: "/career",
    //   name: "Career",
    // },
  ];

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleNavItemClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Navbar expand="lg" fixed="top" className="main-nav bg-black py-2 ">
        <Container>
          <NavLink to="/" className="logo flex ">
            <img src={logo} alt="logo" />
            <span className=" cursor-pointer lh-1 self-center logop">
              MINDFUL
              <br />
              HAVEN
            </span>
          </NavLink>

          <Navbar.Toggle className="bg-white" onClick={handleToggle} />

          <Navbar.Collapse in={open}>
            <Nav className="ms-auto nav1">
              {menuData.map((item) => (
                <NavLink
                  to={item.path}
                  key={item.path}
                  className="nav-item w-fit"
                  onClick={handleNavItemClick}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "#505919",
                          fontWeight: "bold",
                          important: true,
                        }
                      : {}
                  }
                >
                  <span className="w-fit">{item.name}</span>
                </NavLink>
              ))}
            </Nav>

            <Nav className="ms-auto flex flex-row gap-3 items-center">
              <NavLink
                to="/contact"
                className="btn-nav px-3 py-[7px] mb-[8px] md:mb-0"
                onClick={handleNavItemClick}
              >
                CONTACT
              </NavLink>
              {/* {!admin ? (
                <NavLink
                  to="/admin-login"
                  className="flex items-center"
                  onClick={handleNavItemClick}
                >
                  <GrUserAdmin size={25} color="white" />
                </NavLink>
              ) : (
                <AdminMenu />
              )} */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default TopNav;
