import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TopNav from "./Navbar/TopNav";
import Scrolltoup from "./Componentss/Scrool/Scrool";
import Socialicon from "./Componentss/Socialicon";
import Footers from "./Componentss/Footers/Footer";
import PageNotFound from "./Componentss/utils/PageNotFound";
import ProtectedAdminRoute from "./Componentss/Auth/ProtectAdmin";
import { ThreeDots } from "react-loader-spinner";
import Home from "./Componentss/Home/Home";
import About from "./Componentss/About/About";
import Contact from "./Componentss/Contacts/Contact";
import Portfolio from "./Componentss/Portfolio/portfolio";
import Post from "./Componentss/Post/Post";
import Calendar from "./Caleneder";
import Meeting from "./Componentss/Meeting";
import Certification from "./Componentss/Blog/Blog";
import Pricing from "./Componentss/Pricing/Pricing";
import Career from "./Componentss/Careers/Career";
import BlogEditor from "./Componentss/admin/create-blog/BlogEditor";
import AdminLogin from "./Componentss/admin/auth/AdminLogin";
import AllBlogs from "./Componentss/admin/all-blogs/AllBlogs";
import EditBlog from "./Componentss/admin/edit-blog/EditBlog";
import Services from "./Componentss/Our Services/Services";
import SingleBlog from "./Componentss/SingleBlog/SinglBlog";
import Development from "./Componentss/Serviespage/Development";
import Appdevelopment from "./Componentss/Serviespage/Appdevelopment";
import UIDesign from "./Componentss/Serviespage/UIDesign";
import SEOContent from "./Componentss/Serviespage/SEOContent";
import ProjMgmt from "./Componentss/Serviespage/ProjMgmt";
import QATesting from "./Componentss/Serviespage/QATesting";
import SoftwareMntce from "./Componentss/Serviespage/SoftwareMntce";
import DevOps from "./Componentss/Serviespage/DevOps";
import Graphicdes from "./Componentss/Serviespage/Graphicdes";
const Loader = () => (
  <div className="flex justify-center items-center h-screen">
    <ThreeDots color="#891cf0" height={80} width={80} />
  </div>
);

const RouterPage = () => {
  return (
    <>
      <BrowserRouter>
        <Scrolltoup />
        <TopNav />
        <Socialicon />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/post/:slug" element={<Post />} />
            <Route path="/caleneder" element={<Calendar />} />
            <Route path="/meeting" element={<Meeting />} />
            <Route path="/certification" element={<Certification />} />
            <Route path="/pri" element={<Pricing />} />
            <Route path="/career" element={<Career />} />
            <Route path="/blog/:title" element={<SingleBlog />} />
            {/* for services page */}
            <Route path="/development" element={<Development />} />
            <Route path="/Appdevelop" element={<Appdevelopment />} />
            <Route path="/uiDesign" element={<UIDesign />} />
            <Route path="/graphicdes" element={<Graphicdes />} />
            <Route path="/seoContent" element={<SEOContent />} />
            <Route path="/projMgmt" element={<ProjMgmt />} />
            <Route path="/qATesting" element={<QATesting />} />
            <Route path="/softwareMntce" element={<SoftwareMntce />} />
            <Route path="/devOps" element={<DevOps />} />
            <Route path="*" element={<PageNotFound />} />
            <Route
              path="/admin/create-blog"
              element={
                <ProtectedAdminRoute>
                  <BlogEditor />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/admin/all-blogs"
              element={
                <ProtectedAdminRoute>
                  <AllBlogs />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/admin/blog/edit/:title"
              element={
                <ProtectedAdminRoute>
                  <EditBlog />
                </ProtectedAdminRoute>
              }
            />
            <Route path="/admin-login" element={<AdminLogin />} />
          </Routes>
        </Suspense>
        <Footers />
      </BrowserRouter>
    </>
  );
};

export default RouterPage;
