// import React, { useState } from "react";
// import { Helmet } from "react-helmet";
// import BlogsFilter from "./BlogFilter";
// import useFetch from "../../hooks/useFetch";
// import { ThreeDots } from "react-loader-spinner";
// import BlogCard from "./BlogCard";
// import logo from "../../image/logol.png";

// function Blog() {
//   const [searchText, setSearchText] = useState("");
//   const { data, error, loading } = useFetch(
//     "https://tech-creator-backend.onrender.com/api/blogs/"
//   );

//   if (loading) {
//     return (
//       <div className="flex justify-center items-center h-screen">
//         <ThreeDots color="#891cf0" height={80} width={80} />
//       </div>
//     );
//   }

//   const pageTitle = "All Blogs - TechCreator";
//   const pageDescription =
//     "Read the latest blogs on various topics at TechCreator. Stay updated with our blog collection.";

//   const keywords = [
//     "TechCreator blogs",
//     "latest tech blogs",
//     "technology articles",
//     "tech news",
//     "software development blogs",
//     "web development articles",
//     "programming tutorials",
//     "tech industry updates",
//     "AI blogs",
//     "machine learning articles",
//     "tech tips",
//     "coding tips",
//   ].join(", ");

//   return (
//     <div>
//       <Helmet>
//         <title>{pageTitle}</title>
//         <meta name="description" content={pageDescription} />
//         <meta name="keywords" content={keywords} />
//         <meta property="og:title" content={pageTitle} />
//         <meta property="og:description" content={pageDescription} />
//         <meta property="og:url" content={window.location.href} />
//         <meta property="og:type" content="website" />
//         <meta name="twitter:card" content="summary" />
//         <meta name="twitter:title" content={pageTitle} />
//         <meta name="twitter:description" content={pageDescription} />
//         <script type="application/ld+json">
//           {JSON.stringify({
//             "@context": "https://schema.org",
//             "@type": "CollectionPage",
//             name: pageTitle,
//             description: pageDescription,
//             mainEntity: data?.blogs?.map((blog) => ({
//               "@type": "BlogPosting",
//               headline: blog.title,
//               image: blog.image.imageUrl,
//               author: {
//                 "@type": "Person",
//               },
//               publisher: {
//                 "@type": "Organization",
//                 name: "TechCreator",
//                 logo: {
//                   "@type": "ImageObject",
//                   url: logo,
//                 },
//               },
//               datePublished: blog.publishedAt,
//               description: blog.description,
//               mainEntityOfPage: {
//                 "@type": "WebPage",
//                 "@id": `${window.location.origin}/blog`,
//               },
//             })),
//           })}
//         </script>
//       </Helmet>
//       <div className="w-full h-[340px] justify-center flex items-center bg-black ">
//         <h1 className="text-white">Our Latest News & Articles</h1>
//       </div>
//       <div className="flex flex-col-reverse md:flex-row gap-4 mt-20 py-6 !pb-20">
//         <div className="flex-1 flex flex-col gap-3 px-5">
//           {data?.blogs?.length === 0 && (
//             <div className="flex items-center justify-center font-bold text-4xl h-[50vh]">
//               No Blogs
//             </div>
//           )}
//           {data?.blogs?.map((blog) => (
//             <BlogCard key={blog._id} blog={blog} />
//           ))}
//         </div>
//         {data?.blogs?.length !== 0 && (
//           <BlogsFilter setSearchText={setSearchText} searchText={searchText} />
//         )}
//       </div>
    
//     </div>
//   );
// }

// export default Blog;
import React from "react";
import { Helmet } from "react-helmet";
// import CertificationsFilter from "./CertificationsFilter";
import { ThreeDots } from "react-loader-spinner";
import CertificationCard from "./BlogCard";
import logo from "../../image/logol.png";
import C1 from "../../image/c1.jpg";

function Certification() {
  const staticCertifications = [
    {
      _id: "1",
      title: "React Developer Certification",
      image: { imageUrl: "../../image/c1.jpg" },
      description: "Earn your certification in React development and showcase your skills.",
      issuedAt: "2024-01-15",
    },
    // {
    //   _id: "2",
    //   title: "Node.js Professional Certification",
    //   image: { imageUrl: "/path/to/image2.jpg" },
    //   description: "Become a certified Node.js professional and advance your career.",
    //   issuedAt: "2024-02-10",
    // },
    // {
    //   _id: "3",
    //   title: "AI and Machine Learning Certification",
    //   image: { imageUrl: "/path/to/image3.jpg" },
    //   description: "Get certified in AI and machine learning concepts and tools.",
    //   issuedAt: "2024-03-05",
    // },
  ];

  const pageTitle = "All Certifications - TechCreator";
  const pageDescription =
    "Explore our range of certifications to boost your skills and advance your career. Stay updated with our certification programs.";

  const keywords = [
    "TechCreator certifications",
    "professional certifications",
    "React certification",
    "Node.js certification",
    "AI certifications",
    "machine learning certifications",
    "technology certifications",
    "programming certifications",
    "certification programs",
    "career advancement",
    "certification tips",
    "online certifications",
  ].join(", ");

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            name: pageTitle,
            description: pageDescription,
            mainEntity: staticCertifications.map((certification) => ({
              "@type": "CreativeWork",
              headline: certification.title,
              image: certification.image.imageUrl,
              author: {
                "@type": "Person",
              },
              publisher: {
                "@type": "Organization",
                name: "TechCreator",
                logo: {
                  "@type": "ImageObject",
                  url: logo,
                },
              },
              datePublished: certification.issuedAt,
              description: certification.description,
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": `${window.location.origin}/certification`,
              },
            })),
          })}
        </script>
      </Helmet>
      <div className="w-full h-[340px] justify-center flex items-center bg-black">
        <h1 className="text-white">Our Certifications</h1>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-4 mt-20 py-6 !pb-20">
        <div className="flex-1 flex flex-col gap-3 px-5">
          {staticCertifications.length === 0 && (
            <div className="flex items-center justify-center font-bold text-4xl h-[50vh]">
              No Certifications
            </div>
          )}
          {staticCertifications.map((certification) => (
            <CertificationCard key={certification._id} certification={certification} />
          ))}
        </div>
        {/* {staticCertifications.length !== 0 && (
          <CertificationsFilter setSearchText={() => {}} searchText="" />
        )} */}
      </div>
    </div>
  );
}

export default Certification;